import React, { useEffect, useState } from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import HeaderDiv from "../../components/HeaderDiv";
import Loading from "../../components/Loading";
import arrowIcon from "../../assets/icons/left_arrow_gray.svg";

import { breakpoints } from "../../styles/breakpoints";
import { defaultInstance } from "../../shared/Request";
const SignupSelectCharacter = ({
  selectedImage,
  setSelectedImage,
  handleClickNext,
  setPage,
}) => {
  const [loading, setLoading] = useState(true);
  const [profileImgList, setProfileImgList] = useState([]);

  useEffect(() => {
    fetchProfileImgList();
  }, []);

  const fetchProfileImgList = async () => {
    setLoading(true);
    try {
      const response = await defaultInstance.get("/characters");
      //console.log("Response:", response.data);
      setProfileImgList(response.data.characterList);
      setLoading(false);
    } catch (error) {
      //console.error("Error fetch Profile Img List:", error);
      // Handle error
    }
  };

  const handleChangeImage = (image) => {
    if (selectedImage.characterCode === image.characterCode) {
      return ""; // 동일한 이미지면 아무 것도 하지 않음
    } else {
      setSelectedImage(image); // 새로운 이미지를 선택

      return "selected";
    }
  };

  return (
    <div>
      <Global styles={GlobalStyles} />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Wrapper>
            <Container>
              <TitleContainer>
                <BackBtn onClick={() => setPage("Input")}>
                  <img src={arrowIcon} alt="back" />
                  <p>Back</p>
                </BackBtn>
                <Title>Select Character</Title>
                <SubTitle>Please select your profile character.</SubTitle>
              </TitleContainer>

              <ProfileChangeContainer>
                <CurrentImageContainer>
                  <CurrentImageBox>
                    <ProfileImg>
                      <img
                        src={selectedImage.storageFileName}
                        alt={selectedImage.characterCode}
                      />
                    </ProfileImg>
                  </CurrentImageBox>
                </CurrentImageContainer>
                <ImageListContainer>
                  {profileImgList.map((image) => (
                    <ImageBox
                      key={image.characterCode}
                      onClick={() => handleChangeImage(image)}
                      className={
                        selectedImage.characterCode === image.characterCode
                          ? "selected"
                          : ""
                      }
                    >
                      <img
                        src={image.storageFileName}
                        alt={image.characterCode}
                      />
                    </ImageBox>
                  ))}
                </ImageListContainer>
              </ProfileChangeContainer>
              <FinishBtn type="submit" onClick={handleClickNext}>
                <BtnText>Next</BtnText>
              </FinishBtn>
            </Container>
          </Wrapper>
        </div>
      )}
    </div>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media (max-width: ${breakpoints.mobile}) {
    padding: 10px 2px;
  }
`;
const FinishBtn = styled.button`
  width: 440px;
  height: 50px;
  margin: 40px 0px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
    flex-shrink: 0;

    margin: 40px auto 14px auto;
  }
`;

const BtnText = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;

const ProfileChangeContainer = styled.div`
  display: flex;
  gap: 14px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
  }
`;
const ProfileImg = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    img {
      width: 120px;
      margin-top: 10px;
      margin-left: 0px;
      transform: scale(1.5);
      overflow: hidden;
    }
  }
`;
const CurrentImageContainer = styled.div`
  width: 355px;
  height: 297px;
  border-radius: 15px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    height: 120px;
    display: flex;
  }
`;

const ImageListContainer = styled.div`
  width: 445px;
  height: 220px;
  border-radius: 15px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  .selected {
    border: 3px solid var(--main, #90a9f4);
    background: rgba(144, 169, 244, 0.3);
    &:hover {
      background: rgba(144, 169, 244, 0.5);
      transition: 0.2s;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 170px;
    padding: 0px;
    gap: 9px;
    overflow: hidden;
  }
`;

const ImageBox = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
    transition: 0.2s;
  }
  img {
    margin-top: 18px;
    transform: scale(1.5);
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 78px;
    height: 78px;

    border-radius: 10px;
  }
`;

const CurrentImageBox = styled.div`
  width: 168px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 168px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @media (max-width: ${breakpoints.mobile}) {
    width: 120px;
    height: 120px;
    border-radius: 120px;
  }
`;

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: rgba(243, 245, 251, 1);
    }
  }
`;
const Wrapper = styled.div`
  gap: 14px;
  display: flex;
  width: 1100px;
  height: 518px;
  margin: 20px auto 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 640px;
    margin: 14px auto;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    background-color: white;
  }
`;

const BackBtn = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  img {
    stroke-width: 2px;
    stroke: var(--gray, #aaa);
  }
  P {
    color: var(--gray, #aaa);
    font-family: "Wanted Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      color: #717171;
      transition: 0.2s;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 54px;
    p {
      font-size: 12px;
    }
  }
`;
const Title = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const SubTitle = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    margin-top: 5px;
  }
`;
const TitleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 36px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 324px;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 5px 0px 15px 20px;
  }
`;
export default SignupSelectCharacter;
