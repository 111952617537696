import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./ReviewSlider.module.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import leftArrow from "../../../assets/icons/left_arrow.svg";
import rightArrow from "../../../assets/icons/right_arrow.svg";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

// Import required modules
import Loading from "../../../components/Loading";
import { useQuery } from "react-query";
import { fetchTodayReviews } from "../../../apis/mainApi";
import { ReviewCard } from "./ReviewCard";

export default function ReviewSlider({ category, setPopupVisible }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const {
    isLoading,
    isError,
    data: reviewsData,
    error,
  } = useQuery("todaysReviews", fetchTodayReviews, {
    onError: (e) => {
      console.log(e.message);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  console.log("reviewsData", reviewsData);

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"3"}
        coverflowEffect={{
          rotate: 0,
          stretch: 15,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: `.${styles.swiperPagination}`, clickable: true }}
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
          clickable: true,
        }}
        onBeforeInit={(swiper) => {
          // Ensure swiper can find the correct elements
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={styles.swiperContainer}
      >
        {reviewsData.map((domain) => {
          if (category === "All") {
            return domain.domainReviews.map((review, i) => (
              <SwiperSlide key={"all" + i} className={styles.swiperSlide}>
                <ReviewCard review={review} setPopupVisible={setPopupVisible} />
              </SwiperSlide>
            ));
          }
          if (domain.domainType === category) {
            return domain.domainReviews.map((review, i) => (
              <SwiperSlide key={category + i} className={styles.swiperSlide}>
                <ReviewCard review={review} setPopupVisible={setPopupVisible} />
              </SwiperSlide>
            ));
          }
        })}

        <div className={styles.sliderControler}>
          <div
            ref={prevRef}
            className={`swiper-button-prev ${styles.sliderArrow}`}
          >
            <img src={leftArrow} alt="leftArrow" />
          </div>
          <div
            ref={nextRef}
            className={`swiper-button-next ${styles.sliderArrow}`}
          >
            <img src={rightArrow} alt="rightArrow" />
          </div>
          <div className={styles.swiperPagination}></div>
        </div>
      </Swiper>
    </>
  );
}

const LeftArrow = styled.button``;
