import React, { useState } from "react";
import styled from "@emotion/styled";
import { authInstance } from "../shared/Request";
import { useQuery } from "react-query";
import { fetchTodayFoods } from "../apis/mainApi";

import ThumUp from "../assets/icons/thumb-up-white.svg";
import ThumUpFull from "../assets/icons/thumb-up-white-full.svg";

import * as Sentry from "@sentry/react";

export const MainThumUpBtn = ({ review, ReaderThumbUpId, setPopupVisible }) => {
  const [thumbUpId, setThumbUpId] = useState(ReaderThumbUpId);

  const [clickCount, setClickCount] = useState(
    review.reviewContent.thumbUpCount
  );

  const { refetch } = useQuery("todaysFood", fetchTodayFoods);

  const handleFavoriteClick = async (event) => {
    console.log(thumbUpId);
    try {
      if (thumbUpId) {
        await deleteReviewThumb();
      } else {
        await postReviewThumb();
      }
      refetch();
    } catch (error) {
      //console.error("Error handling favorite click:", error);
      Sentry.captureException(error);
    }
  };

  const postReviewThumb = async () => {
    const data = {
      domainReviewId: review.reviewContent.domainReviewId,
    };
    try {
      const response = await authInstance.post(`/member/review-thumbs`, data);
      console.log("Thum Up 전달완료");
      console.log(response.data);
      setClickCount(response.data.thumbUpCount);
      setThumbUpId(response.data.thumbUpId);
    } catch (error) {
      setPopupVisible(true);
      //console.error("Error sending Favorite Food to server:", error);
      console.clear();
      Sentry.captureException(error);
    }
  };

  const deleteReviewThumb = async () => {
    console.log(thumbUpId);

    try {
      const response = await authInstance.delete(
        `/member/review-thumbs/${thumbUpId}`,
        {
          data: {
            domainReviewId: review.reviewContent.domainReviewId,
          },
        }
      );
      console.log("Thum Up 삭제완료");
      console.log(response.data);
      setClickCount(response.data.thumbUpCount);
      setThumbUpId(0);
    } catch (error) {
      //console.error("Error sending Delete Favorite Food to server:", error);
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {thumbUpId === 0 ? (
        <ThumbUpContainer
          backgroundColor="#ffffff"
          onClick={handleFavoriteClick}
        >
          <img src={ThumUp} alt="thumup" width="22px" />
          <ThumUpCount color="#ffffff">{clickCount}</ThumUpCount>
        </ThumbUpContainer>
      ) : (
        <ThumbUpContainer
          backgroundColor="#8397D3"
          onClick={handleFavoriteClick}
        >
          <img src={ThumUpFull} alt="thumup" />
          <ThumUpCount color="#ffffff">{clickCount}</ThumUpCount>
        </ThumbUpContainer>
      )}
    </>
  );
};

const ThumUpCount = styled.span`
  color: ${(props) => props.color || "#000000"};
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 600;
`;
const ThumbUpContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  gap: 4px;
`;
