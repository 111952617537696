import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import HeaderDiv from "../../components/HeaderDiv";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { defaultInstance } from "../../shared/Request";

import { setNickname } from "../../modules/user";
import { InformationInput } from "./InformationInput";
import { InformationCheck } from "./InformationCheck";
import SignupSelectCharacter from "./SignupSelectCharacter";
import { useQuery } from "react-query";
import { fetchHome } from "../../apis/memberApi";

import { useQueryClient } from "react-query";

const SignupProfileDetails = () => {
  const { code } = useParams();

  console.log("회원가입 페이지 code:", code);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userNickname, setUserNickname] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("NA");
  const [isActive, setIsActive] = useState(true);

  const [selectedImage, setSelectedImage] = useState({
    characterCode: "nm",
    storageFileName:
      "https://d2jyfnyrngm2cd.cloudfront.net/profile/noble_man.png",
  });

  const [page, setPage] = useState("Input");

  // React Query Client 가져오기
  const queryClient = useQueryClient();

  const handleSignUpClick = () => {
    if (userNickname.length < 1) {
      alert("Please enter a nickname.");
    } else {
      setPage("Character");
    }
  };

  const handleClickNext = () => {
    setPage("Check");
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    console.log(userNickname);
    console.log("country_value: ", country);
    console.log(selectedImage);

    if (!userNickname) {
      setMessage("Please generate or enter a nickname first.");
      alert("Please enter a nickname.");
      return;
    }
    try {
      let data = {
        nickname: userNickname,
        nationality: country.value,
        code,
        characterCode: selectedImage.characterCode,
        subcribe: isActive ? true : false,
      };
      if (country === "NA") {
        data = {
          nickname: userNickname,
          nationality: country,
          code: code,
          characterCode: selectedImage.characterCode,
          subcribe: isActive ? true : false,
        };
      }
      console.log(data, "전달전");

      const response = await defaultInstance.post(`/members`, data);
      console.log(data, "전달완료");

      setMessage("Nickname successfully sent to the server!");
      dispatch(setNickname(response.data.nickname));

      // React Query 캐시 무효화
      queryClient.invalidateQueries("home");
      navigate("/");
    } catch (error) {
      console.log(userNickname);
      //console.error("Error sending nickname to server:", error);
      setMessage("Failed to send nickname to the server.");
      console.log(message);
      alert("Sign-up failed.");
    }
  };

  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      {(() => {
        switch (page) {
          case "Input":
            return (
              <InformationInput
                userNickname={userNickname}
                setUserNickname={setUserNickname}
                country={country}
                setCountry={setCountry}
                handleSignUpClick={handleSignUpClick}
              />
            );
          case "Character":
            return (
              <SignupSelectCharacter
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                handleClickNext={handleClickNext}
                setPage={setPage}
              />
            );
            break;
          case "Check":
            return (
              <InformationCheck
                userNickname={userNickname}
                country={country}
                selectedImage={selectedImage}
                setPage={setPage}
                handleSignUp={handleSignUp}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            );
          default:
            return <div>Invalid Page</div>;
        }
      })()}
    </>
  );
};
const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  .country-select {
    width: 100%;
    padding: 20px 15px 15px 15px;
    border: none;
    background: F3F5FB;
    border-radius: 4px;
    font-size: 16px;
  }
`;

export default SignupProfileDetails;
