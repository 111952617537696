import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderDiv from "../../../components/HeaderDiv";
import styled from "@emotion/styled";

import loginIcon from "../../../assets/icons/navigate-login.svg";

import closeIcon from "../../../assets/icons/closeIcon.svg";

const DeletedMember = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const deleteDate = searchParams.get("deleteDate");
  const resignUpAvailableDate = searchParams.get("resignUpAvailableDate");
  return (
    <Wrapper>
      <Popup onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => navigate("/")}>
          <img src={closeIcon} alt="closeIcon" />
        </CloseButton>
        <PopUpContainer>
          <img src={loginIcon} alt="loginIcon" />
          <h2>This account was deleted on {deleteDate}.</h2>
          <h2>You can re-register starting from {resignUpAvailableDate}.</h2>
          <LoginBtn onClick={() => navigate("/")}>
            <p>Go to Home</p>
          </LoginBtn>
        </PopUpContainer>
      </Popup>
    </Wrapper>
  );
};

export default DeletedMember;

const Wrapper = styled.div``;

const LoginBtn = styled.button`
  width: 120px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 0.896px 2.689px 0px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 700;
  }
  &:hover {
    background: #7b93da;
    transition: 0.2s;
  }
`;
const PopUpContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 30px 0 20px 0;
    width: 26px;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

const Popup = styled.div`
  position: relative;
  margin: 25% auto;
  background: white;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 5px;
  width: 350px;
  height: 180px;
  z-index: 5;
  opacity: 1;
  transform: translateY(-20px);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  img {
    width: 10px;
  }
`;
