import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ReviewSlider from "./slider/ReviewSlider";
import ReviewSliderMobile from "./slider/ReviewSliderMobile";
import { breakpoints } from "../../styles/breakpoints";
import LoginRequired from "../../components/notification-popups/LoginRequired";

export const TodaysReview = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isPopupVisible, setPopupVisible] = useState(false);
  // 브라우저 너비를 상태로 관리
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // 브라우저가 리사이즈 될 때마다 너비를 업데이트
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper>
      {isPopupVisible && (
        <LoginRequired onClose={() => setPopupVisible(false)} />
      )}
      <TitleContainer>
        <Title>
          <h1>TODAY’s Review</h1>
          <span
            className={selectedCategory === "All" ? "active" : ""}
            onClick={() => handleCategoryClick("All")}
          >
            All
          </span>
          <span
            className={selectedCategory === "K-Food" ? "active" : ""}
            onClick={() => handleCategoryClick("K-Food")}
          >
            K-Food
          </span>
          <span
            className={selectedCategory === "K-Place" ? "active" : ""}
            onClick={() => handleCategoryClick("K-Place")}
          >
            K-Place
          </span>
        </Title>
      </TitleContainer>
      {windowWidth <= breakpoints.mobile ? (
        <ReviewSliderMobile category={selectedCategory} />
      ) : (
        <ReviewSlider
          category={selectedCategory}
          setPopupVisible={setPopupVisible}
        />
      )}
    </Wrapper>
  );
};

const Title = styled.div`
  h1 {
    color: #000;

    /* Headline */
    font-family: "Wanted Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  span {
    color: #7f7f7f;
    text-align: center;

    /* subtitle */
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 20px;
    cursor: pointer;
  }
  .active {
    color: #000;
  }
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 25px;
    }
    span {
      font-size: 13px;

      margin-right: 16px;
    }
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 20px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 300px;
    align-items: start;
  }
`;
const Wrapper = styled.div`
  width: 1200px;
  height: fit-content;
  padding: 30px;
  margin: 100px auto 130px auto;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 300px;
    height: fit-content;
    margin: 90px auto 0px auto;
    display: none;
    flex-direction: column;
    background-color: aliceblue;
  }
`;
