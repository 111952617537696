import React from "react";
import styled from "@emotion/styled";

import koriCommentCharacter from "../../assets/images/kori_comment_character.png";
import koriCommentDesk from "../../assets/icons/kori-comment-desk.svg";
import koriCommentTab from "../../assets/icons/kori-comment-tab.svg";
import koriCommentMobile from "../../assets/icons/kori-comment-mobile.svg";
import { breakpoints } from "../../styles/breakpoints";

const KoriComment = ({ comment }) => {
  return (
    <KoriCommentContainer>
      <KoriCommentTextContainer>
        <h1>Kori’s comment</h1>
        <h2>{comment}</h2>
      </KoriCommentTextContainer>
      <KoriCommentImgContainer>
        <img src={koriCommentDesk} alt="kori_comment" className="largeImg" />
        <img src={koriCommentTab} alt="kori_comment" className="middleImg" />
        <img src={koriCommentMobile} alt="kori_comment" className="smallImg" />
        <img src={koriCommentCharacter} alt="kori" className="kori" />
      </KoriCommentImgContainer>
    </KoriCommentContainer>
  );
};

const KoriCommentTextContainer = styled.div`
  width: 330px;
  height: 35px;
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: start;
  z-index: 5;
  position: absolute;
  opacity: 1;
  margin-top: 8px;
  overflow: hidden;
  h1 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }
  h2 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 400;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 225px;
    height: 35px;
  }
`;
const KoriCommentImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img.smallImg {
    display: none;
  }
  img.middleImg {
    display: none;
  }
  .kori {
    width: 46px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    img.largeImg {
      display: none;
    }
    img.middleImg {
      display: block;
    }

    img.smallImg {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .kori {
      width: 46px;
    }
    img.largeImg {
      display: none;
    }
    img.middleImg {
      display: none;
    }

    img.smallImg {
      display: block;
    }
  }
`;
const KoriCommentContainer = styled.div``;

export default KoriComment;
