import React from "react";
import styled from "@emotion/styled";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import CountryFlag from "react-country-flag";
import { MainThumUpBtn } from "../../../components/MainThumUpBtn";

export const ReviewCard = ({ review, setPopupVisible }) => {
  return (
    <CardContainer img={review.reviewContent.imgLink}>
      <ReviewContainer>
        <ReviewWriter>
          <UserProfileImg>
            <img src={review.reviewWriter.profileCharacter} alt="profile-img" />
          </UserProfileImg>
          <UserNationality>
            {review.reviewWriter.nationality === "NA" ? null : (
              <CountryFlag
                countryCode={review.reviewWriter.nationality}
                svg
                style={{
                  height: 12,
                  borderRadius: 2,
                  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.15)",
                }}
              />
            )}
          </UserNationality>
          <UserProfile>
            <Nickname>{review.reviewWriter.memberNickname}</Nickname>
            <div>
              <ReviewCount>
                {review.reviewWriter.reviewNumberOfMember + " reviews"}
              </ReviewCount>
              <p className="middle-point">·</p>
              <Date>{review.reviewContent.reviewDate}</Date>
            </div>
          </UserProfile>
          <ThumbUpContainer>
            <MainThumUpBtn
              review={review}
              ReaderThumbUpId={review.reviewReader.thumbUpId}
              setPopupVisible={setPopupVisible}
            />
          </ThumbUpContainer>
        </ReviewWriter>
        <ReviewContent>
          <Foodname>
            Food name : <span>{review.reviewContent.domainName}</span>
          </Foodname>
          <Comment>
            <p>{review.reviewContent.comment}</p>
          </Comment>
          <KeywordBox>
            <Keyword>
              {review.reviewContent.memberSelectTags[0].emoji +
                review.reviewContent.memberSelectTags[0].tagValue}
            </Keyword>
            <ExtraCount>
              +{review.reviewContent.memberSelectTags.length - 1}
            </ExtraCount>
          </KeywordBox>
        </ReviewContent>
      </ReviewContainer>
    </CardContainer>
  );
};

const UserNationality = styled.div`
  position: absolute;
  z-index: 2;
  left: 24px;
  bottom: -3px;
  transform: scale(1);
`;
const Keyword = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 10px 4px 5px;
  align-items: center;
  border-radius: 3px;
  background: #fff;
  margin-right: 5px;
  font-size: 14px;
`;

const ExtraCount = styled(Keyword)`
  padding: 4px 5px 4px 5px;
`;
const KeywordBox = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;
const ReviewContent = styled.div``;
const UserProfile = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 4px;
  div {
    display: flex;
    align-items: center;
  }
  line-height: 10px;
  .middle-point {
    font-size: 18px;
    color: white;
    margin: 0 5px;
  }
`;

const UserProfileImg = styled.div`
  background-color: #ffffff;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  overflow: hidden;
  position: relative;
  img {
    width: 60px;
    position: absolute;
    left: -10px;
    top: -10px;
    margin-top: 3px;

    transform: scale(0.95);
  }
`;
const ReviewWriter = styled.div`
  width: 270px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;
const ReviewContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 304px;
  height: 164px;
  padding: 20px;
  justify-content: start;
  align-items: start;
  position: absolute;
  margin: 0 auto;
  bottom: 10px;
  border-radius: 6px;
  background: var(--black-blender, rgba(0, 0, 0, 0.4));
  backdrop-filter: blur(2.5px);
`;

const Nickname = styled.p`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const ReviewCount = styled.p`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Date = styled(ReviewCount)``;
const Foodname = styled.p`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }
`;
const Comment = styled.div`
  width: 265px;
  height: 35px;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const ThumbUpContainer = styled.div`
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background-image: url(${(props) => `${props.img}`});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
`;
