import React from "react";
import styled from "@emotion/styled";
import arrowIcon from "../../assets/icons/left_arrow_gray.svg";

import CountryFlag from "react-country-flag";
import kori from "../../assets/images/kori.jpg";
import EmailToggle from "../../components/EmailToggle";
import { breakpoints } from "../../styles/breakpoints";

export const InformationCheck = ({
  userNickname,
  country,
  selectedImage,
  setPage,
  handleSignUp,
  isActive,
  setIsActive,
}) => {
  return (
    <Wrapper>
      <TitleContainer>
        <BackBtn onClick={() => setPage("Character")}>
          <img src={arrowIcon} alt="back" />
          <p>Back</p>
        </BackBtn>
        <Title>Almost there!</Title>
        <SubTitle>
          Finish creating your account for the full Korea experience.
        </SubTitle>
      </TitleContainer>
      <ProfileContainer>
        <KoriImg src={selectedImage.storageFileName} alt="kori-profile" />
        <NicknameContainer>
          <p>{userNickname}</p>
          <CountryFlag
            countryCode={country.value}
            svg
            style={{
              width: 20,
              borderRadius: 2,
              boxShadow: "2px 2px px 0px rgba(0, 0, 0, 0.15)",
            }}
          />
        </NicknameContainer>
        <WarningText>
          ※Once your nationality is set, it cannot be changed
        </WarningText>
      </ProfileContainer>

      <BottomContainer>
        <ToggleContainer>
          <Label>Receive updates via email</Label>

          <EmailToggle isActive={isActive} setIsActive={setIsActive} />
        </ToggleContainer>

        <StartKoriBtn onClick={handleSignUp}>
          <p>Start Kori</p>
        </StartKoriBtn>
      </BottomContainer>
    </Wrapper>
  );
};

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.mobile}) {
    padding: 60px 0px 15px 0px;
  }
`;
const ToggleContainer = styled.div`
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: var(--gray, #aaa);
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 10px;
  }
`;

const StartKoriBtn = styled.button`
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: var(--main, #90a9f4);

  transition: ease-in-out 0.2s;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background: #8098e0;
    transition: ease-in-out 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
    p {
      font-size: 14px;
    }
  }
`;
const KoriImg = styled.img`
  width: 140px;
  margin-bottom: 0px;
  @media (max-width: ${breakpoints.mobile}) {
  }
`;
const NicknameContainer = styled.div`
  box-sizing: border-box;
  min-width: 166px;
  max-width: 220px;
  height: 36px;
  padding: 8px 30px;
  background-color: #f3f5fb;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 4px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    min-width: 130px;
    max-width: 166px;
    p {
      font-size: 14px;
    }
  }
`;
const WarningText = styled.span`
  color: #f00;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const BackBtn = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  img {
    stroke-width: 2px;
    stroke: var(--gray, #aaa);
  }
  P {
    color: var(--gray, #aaa);
    font-family: "Wanted Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      color: #717171;
      transition: 0.2s;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 54px;
    p {
      font-size: 12px;
    }
  }
`;
const Title = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const SubTitle = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const TitleContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 36px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 324px;
    margin-top: 0px;
    padding: 16px 0px 15px 8px;
  }
`;
const Wrapper = styled.div`
  width: 95vw;
  height: 580px;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 640px;
    padding: 0px 0 0 0;
    justify-content: space-between;
  }
`;
