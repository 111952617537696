import React, { useState } from "react";

import styled from "@emotion/styled";
import checkNoneIcon from "../../assets/icons/password-check-gray.svg";
import checkIcon from "../../assets/icons/password-check-blue.svg";
import showPasswordIcon from "../../assets/icons/show-password.svg";
import hidePasswordIcon from "../../assets/icons/hide-password.svg";

import { defaultInstance } from "../../shared/Request";
import { useNavigate } from "react-router-dom";
import TryAgin from "../../components/notification-popups/TryAgin";
import InputLael from "./InputComponents/InputLabel";
import { breakpoints } from "../../styles/breakpoints";

import * as Sentry from "@sentry/react";

const SignUpForm = () => {
  const navigate = useNavigate();

  const type = "sign-up";
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [isVerified, setIsVerified] = useState(false);

  const [isSendCodeDisabled, setSendCodeDisabled] = useState(false); // Send code 버튼 비활성화 상태
  const [timer, setTimer] = useState(0); // 타이머 상태

  console.log(errors);
  //이메일 형식 검증
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  //비밀번호 일치 검증
  const validatePasswordMatch = (password, password2) => {
    return password.length > 0 && password === password2;
  };

  //비밀번호 형식 검증
  const validatePassword = (password) => {
    const minLength = 8;
    const errors = [];

    if (password.length < minLength) {
      errors.push(
        `The password must be at least ${minLength} characters long.`
      );
    }
    if (!/[A-Za-z]/.test(password)) {
      errors.push("The password must contain at least one letter.");
    }
    if (!/\d/.test(password)) {
      errors.push("The password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("The password must contain at least one special character.");
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Please complete email verification.");
      return 0;
    }
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email format.";
    }

    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      // 첫 번째 비밀번호 오류만 저장
      newErrors.password = passwordErrors[0];
    }

    if (!validatePasswordMatch(password, password2)) {
      newErrors.password2 = "The passwords do not match.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // 유효성 검사가 모두 통과하면 로그인 로직 실행
      console.log("로그인 시도:", { email, password });
      postEmailSignUp();
    }
  };

  const postEmailCode = async (email) => {
    let newErrors = {};
    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email format.";

      setErrors(newErrors);
    } else {
      setSendCodeDisabled(true); // 버튼 비활성화
      setTimer(60); // 1분 타이머 시작

      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown); // 타이머 종료
            setSendCodeDisabled(false); // 버튼 활성화
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      try {
        const response = await defaultInstance.post("email-code", {
          email: email,
          type: type,
        });
      } catch (error) {
        if (error.response) {
          // 서버로부터 응답을 받은 경우
          switch (error.response.status) {
            case 400:
              newErrors.email = "Already registered.";
              break;
            case 403:
              newErrors.email = "Account deactivated.";
              break;
            case 500:
              newErrors.email = "Please try again.";
              break;
            default:
              newErrors.email = "Please try again.";
              break;
          }
        }
        setErrors(newErrors);
      }
    }
  };

  const postVerifyCode = async (code) => {
    try {
      const response = await defaultInstance.post("verify-code", {
        verificationCode: code,
      });
      console.log(response.status);

      if (response.status) {
        setIsVerified(true);
      }
    } catch (error) {
      //console.error("Error verifing email code:", error);
    }
  };

  const postEmailSignUp = async () => {
    try {
      const response = await defaultInstance.post("email-sign-up", {
        email: email,
        verificationCode: code,
        password: password,
      });
      console.log(response.data);

      const redirectCode = response.data.code; // 응답에서 redirectCode 추출
      if (redirectCode) {
        navigate(`/auth/sign-up/${redirectCode}`); // redirectCode를 쿼리 파라미터로 전달
      }
    } catch (error) {
      if (error.response) {
        const newErrors = {};
        const { detail, status } = error.response.data;
        //console.error(`Error during sign-up: ${detail} (Status: ${status})`);

        if (status === 400) {
          newErrors.signUp = detail;
          setErrors(newErrors);
        } else {
          setPopupVisible(true);
        }
      }
    }
  };

  return (
    <FormWrapper>
      {isPopupVisible && <TryAgin onClose={() => setPopupVisible(false)} />}

      {/* email input */}

      <InputLael
        id="email"
        errors={errors}
        isSendCodeDisabled={isSendCodeDisabled}
      />
      <EmailInputContainer>
        <InputContainer>
          <Input
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            minLength={1}
            maxLength={256}
          />
        </InputContainer>
        <MiniBtn
          type="button"
          onClick={() => postEmailCode(email)}
          disabled={isSendCodeDisabled}
        >
          <p>Send code</p>
        </MiniBtn>
      </EmailInputContainer>

      {/* code input */}
      <InputLael id="code" errors={errors} />
      <EmailInputContainer>
        <InputContainer>
          <Input
            id="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            minLength={1}
            maxLength={6}
          />
        </InputContainer>
        <MiniBtn
          type="button"
          onClick={() => postVerifyCode(code)}
          disabled={isVerified}
        >
          <p>Veirify</p>
        </MiniBtn>
      </EmailInputContainer>

      {/* password input */}

      <InputLael id="password" errors={errors} />
      <PasswordContainer>
        <Input
          id="password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={1}
          maxLength={32}
          placeholder="*At least 8 characters with a number and a special character."
        />
        <button
          type="button"
          onMouseDown={() => setShowPassword(true)}
          onMouseUp={() => setShowPassword(false)}
          onMouseLeave={() => setShowPassword(false)}
        >
          <ShowPasswordIcon>
            {showPassword ? (
              <img src={showPasswordIcon} alt="show password" />
            ) : (
              <img src={hidePasswordIcon} alt="show password" />
            )}
          </ShowPasswordIcon>
        </button>
      </PasswordContainer>

      {/* password2 input */}

      <InputLael id="confirm-password" errors={errors} />
      <PasswordContainer>
        <Input
          id="confirm-password"
          type={showPassword ? "text" : "password"}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          required
          minLength={1}
          maxLength={32}
        />
        <PasswordCheckIcon>
          {validatePasswordMatch(password, password2) ? (
            <img src={checkIcon} alt="check" />
          ) : (
            <img src={checkNoneIcon} alt="no check" />
          )}
        </PasswordCheckIcon>
      </PasswordContainer>
      {errors.signUp && <ErrorMessage>{errors.signUp}</ErrorMessage>}

      <LoginBtn type="submit" onClick={handleSubmit}>
        <p>Next</p>
      </LoginBtn>
    </FormWrapper>
  );
};

const ShowPasswordIcon = styled.div`
  margin-right: 10px;
`;
const PasswordCheckIcon = styled.div`
  margin-right: 22px;
  img {
  }
`;
const EmailInputContainer = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    gap: 5px;
  }
`;
const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.mobile}) {
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const ErrorMessage = styled.p`
  width: 310px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
`;

const LoginBtn = styled.button`
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  margin-top: 20px;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background-color: #7791df;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
    position: absolute;
    bottom: 0px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 15px 0px 15px 15px;
    ::placeholder {
      color: #aaa;
      font-family: "Wanted Sans";
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const InputContainer = styled.div`
  position: relative;
  width: 310px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 245px;
    height: 45px;
  }
`;
const PasswordContainer = styled.div`
  position: relative;
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const MiniBtn = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 5px;
  background: ${({ disabled }) =>
    disabled
      ? "#AAA"
      : "var(--main, #90a9f4)"}; // 버튼이 비활성화되면 회색으로 변경
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 60px;
    height: 45px;
    p {
      font-size: 12px;
      line-height: 13px; /* 108.333% */
    }
  }
`;
export default SignUpForm;
